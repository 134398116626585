import React, { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import { Container } from 'react-grid-system'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'
import styles from './Header.module.scss'
import { disableBodyScroll } from 'utils'
import { useOutsideClick, useWindowSize } from 'utils/hooks'
interface HeaderProps {
  ctaFormText: string
  ctaFormId: string
  ctaTicketText: string
  ctaTicketUrl: string
  logoDark: {
    url: string
    fileName: string
  }
  logoLight: {
    url: string
    fileName: string
  }
}

const Header = ({ ctaFormText, ctaFormId, ctaTicketText, ctaTicketUrl, logoDark, logoLight, navigationMenuItems }: HeaderProps) => {
  const { query } = useRouter()
  const [navDrawerIsOpen, handleNavDrawerToggle] = useState(false)
  const { isBreakpointOrBelow: isBreakpointLgOrBelow, windowSizeCalculation } = useWindowSize(breakpoints['lg'], () => handleNavDrawerToggle(false))
  const ref = useRef(null)
  const requestCameFrom = query.camefrom

  useEffect(() => {
    disableBodyScroll(navDrawerIsOpen)
  }, [navDrawerIsOpen])

  const closeDrawerHandler = () => {
    if (navDrawerIsOpen) handleNavDrawerToggle(false)
  }


  if (!windowSizeCalculation) {
    //we can show a loading state instead of null
    return null
  }

  const BrandLogo = () => {
    return (
      <div className={styles['brand-logo']}>
        {logoDark && (
          <div className={'image-container'}>
            <Link href={logoDark.link} target='blank'>
              <Image src={logoDark.url} alt="Sphere" width="0" height="0" sizes="100vw" priority />
            </Link>
          </div>
        )}
      </div>
    )
  }

  const NavDesktop = () => {
    return (
      <>
        <Container className={'container'}>
          <div className={styles['header-global__column']}>
            <div>{BrandLogo('light')}</div>
          </div>
        </Container>
      </>
    )
  }



  return (
    <>
      <header
        ref={ref}
        onClick={closeDrawerHandler}
        className={cx([
          styles['header-global'],
          styles[isBreakpointLgOrBelow ? 'header-global--mobile' : 'header-global--desktop'],
          styles[navDrawerIsOpen && isBreakpointLgOrBelow ? 'drawer-open' : ''],
          styles[!isBreakpointLgOrBelow && ctaFormId && ctaFormText && ctaTicketText && ctaTicketUrl ? 'header-global--has-two-cta-btns' : '']
        ])}
      >
       { NavDesktop()}
      </header>
    </>
  )
}

export default Header
