import { ConstantMapProps } from '~types'

const disableBodyScroll = (modalOpen: boolean) => {
  const html = document.querySelector('html')
  const body = document.querySelector('body')

  if (modalOpen) {
    html.style.overflowY = 'hidden'
    body.style.overflowY = 'hidden'
    // required for iOS
    html.style.touchAction = 'none'
    body.style.touchAction = 'none'
  } else {
    html.style.overflowY = 'auto'
    body.style.overflowY = 'auto'
    html.style.touchAction = 'auto'
    body.style.touchAction = 'auto'
  }
}

const convertDateToMDYYYY = (date: string) => {
  if (!date) return
  const [year, month, day] = date.split('-')
  if (!year || !month || !day) return
  const noLeadingZeroMonth = month.replace(/^0+/, '')
  const noLeadingZeroDay = day.replace(/^0+/, '')
  return `${noLeadingZeroMonth}-${noLeadingZeroDay}-${year}`
}

const objHasValue = (obj: object, key: string, value: string) => {
  return obj.hasOwnProperty(key) && obj[key] === value
}

const getConstantsMapFromCms = (constantMaps: Array<ConstantMapProps>) => {
  return constantMaps.reduce<Record<string, object>>((result, map) => {
    result[map.title] = map.value
    return result
  }, {})
}

const getFontSizeNumber = (fontSize: string | number): number => {
  if (typeof fontSize === 'number') return fontSize
  return Number(fontSize.split('_')[1])
}

const isTouchDevice = () => {
  if (typeof window !== 'undefined') return navigator.maxTouchPoints > 0
}

export { disableBodyScroll, convertDateToMDYYYY, getConstantsMapFromCms, objHasValue, getFontSizeNumber, isTouchDevice }
