import React, { useState, useEffect } from 'react'
import { Container } from 'react-grid-system'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faMap } from '@fortawesome/free-solid-svg-icons'
import { Heading } from '~elements'
import { NavigationMenuItemsProps, SocialMediaItemProps } from '~types'
import styles from './Footer.module.scss'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'
import { useWindowSize } from 'utils/hooks'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'

interface GeneralInfoProps {
  title: string
  addressLine1: string
  addressLine2: string
  phoneNumber: string
}

interface FooterProps {
  showFAQbtn: boolean
  bottomNav: NavigationMenuItemsProps
  legalText: string
  socialMediaRow: Array<SocialMediaItemProps>
  socialMediaRowHeadline: string
  showGeneralInformation: boolean
  generalInformation: GeneralInfoProps
  sponsorLogo: {
    url: string
    fileName: string
  }
  taologo: {
    url: string
    fileName: string
  }
  showSponsorLogo: boolean
  resourcesNav: NavigationMenuItemsProps
  aboutUsNav: NavigationMenuItemsProps
}

const Footer = ({
  logo,
  copyRight,
  socialMediaRow
}: FooterProps) => {
  const copyrightYear = new Date().getFullYear()

  return (
    <>
      <footer className={styles['footer-global']}>
        <Container className={'container'}>
          {socialMediaRow && (
            <div className={styles['footer-bottom']}>
              <div className={styles['social-footer']}>
                <div className={styles['icon-row']}>
                  <ul>
                    {socialMediaRow.map(
                      ({ icon, url, platformName }: NavigationMenuItemProps, navIndex: number) =>
                        url && (
                          <li key={navIndex}>
                            <Link
                              href={url}
                              target={'_blank'}
                            >
                              <Image width={24} height={24} sizes="100vw" alt={platformName} src={icon.url} loading="lazy" />
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                {copyRight && <Link href={copyRight.url} target={'_blank'}>
                  {copyRight.text.indexOf('${YEAR}') !== -1 ? copyRight.text.replace('${YEAR}', `${copyrightYear}`) : copyRight.text}
                </Link>}
              </div>
            </div>
          )}
        </Container>
      </footer>
    </>
  )
}

export default Footer
