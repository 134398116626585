import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FormProps } from '~types'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'

const ReactFormstack = dynamic(() => import('@msgtechnology/react-formstack').then(module => module.default), { ssr: false })

interface FormstackData {
  id: string
}

const Form = ({
  formId,
  inputBgColor = 'white',
  isChildOfModal = false,
  submitButtonBgColor = 'white',
  submitButtonText = 'Sign Up Now',
  formSubmitHandler,
  afterSubmitHandler
}: FormProps) => {
  const { asPath } = useRouter()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)


  const afterSubmit = (data: FormstackData) => {
    if (data.id) {
      setIsSuccessful(true)
      afterSubmitHandler()
    }
  }

  const formClassNames = `formstack formstack--input-bg-color-transparent formstack--submit-btn-bg-${submitButtonBgColor}`

  return (
    <>
      {!isSuccessful && (
        <ReactFormstack className={formClassNames} id={`${formId}`} onAfterSubmit={afterSubmit} submitButtonText={submitButtonText} />
      )}
      {isSuccessful && (
        <div className={`formstack-success ${isChildOfModal ? 'is-child-of-modal' : 'is-child-of-page-content'}`}>
          <Heading headingType={3}>Success!</Heading>
          <p>We've received your request.</p>
          {isChildOfModal && <Button title="close" bgColor="transparent" size="sm" onClick={formSubmitHandler} />}
        </div>
      )}
    </>
  )
}

export default Form
