import {
  Footer,
  Grid,
  Header
} from './modules'
import {
  CardMedia,
  Divider,
  Form,
  Heading,
  ImageLink,
  Paragraph
} from './elements'

export const components = {
  Divider,
  Footer,
  Form,
  CardMedia,
  Grid,
  Header,
  Heading,
  ImageLink,
  Paragraph
}
