import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './CardMedia.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Form, Heading } from '~elements'
import { ImageProps, VimeoProps } from '~types'
import parse from 'html-react-parser'

interface CardMediaProps {
  copy?: { html: string }
  ctaBtnUrl?: string
  ctaBtnText?: string
  ctaBtnIsTargetBlank?: boolean
  hasMediaInRightColumn?: boolean
  headline?: string
  isChildOfGrid?: boolean
  items: Array<CardMediaProps>
  mediaColumn: Array<ImageProps> | Array<VimeoProps>
  subheadline: string
  imgSubheadline: ImageProps
  zIndex: number
}

const CardMediaItem = ({ item, isLastChild }: { item: CardMediaProps; isLastChild: boolean }) => {
  const { copy, hasMediaInRightColumn, headline, subheadline, imgSubheadline, mediaColumn, formHeaderText, formStackId } = item
  const cardMediaStyles = cx([
    styles['card-media'],
    styles[isLastChild ? 'is-last-child' : ''],
    styles[mediaColumn && mediaColumn[0]?.posterImage ? 'has-vimeo-modal' : ''],
    styles[`has-media-in-${hasMediaInRightColumn ? 'right' : 'left'}-col`]
  ])
  const [headerText, setHeaderText] = useState('')

  useEffect(() => {
    if (formHeaderText) {
      setHeaderText(formHeaderText)
    }
  }, [formHeaderText])

  const afterSubmitHandler = () => {
    setHeaderText('')
  }

  return (
    <Col sm={12}>
      <Row className={cardMediaStyles}>
        <Col md={6} className="align-contents-vertical">
          <div className={styles['col-wrapper--content']}>
            {headline && (
              <Heading headingType={2}>
                <>{headline}</>
              </Heading>
            )}
            {subheadline && <p className={styles.subTitle}>{subheadline}</p>}
            {imgSubheadline && <img alt={imgSubheadline.alt} className={styles['img-subheadline']} src={imgSubheadline.image.url} loading="lazy" />}
            {copy?.text && parse(copy.text)}
          </div>
        </Col>
        <Col md={6}>
        <div className={styles['col-wrapper--media']}>
          <Col md={12}>
            <div className={styles['form-container']}>
              <>
              <Heading headingType={4}>
                { headerText }
              </Heading>
              <Form formId={formStackId} afterSubmitHandler={afterSubmitHandler}  submitButtonText='Meet With Us' />
              </>
            </div>
          </Col>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

const CardMedia = ({
  items,
  copy,
  ctaBtnUrl,
  ctaBtnText,
  ctaBtnIsTargetBlank,
  hasMediaInRightColumn,
  headline,
  mediaColumn,
  subheadline,
  imgSubheadline,
  formStackId,
  formHeaderText,
  zIndex
}: CardMediaProps) => {
  const cardMediaItem = {
    copy,
    ctaBtnIsTargetBlank,
    ctaBtnText,
    ctaBtnUrl,
    formHeaderText,
    formStackId,
    hasMediaInRightColumn,
    headline,
    imgSubheadline,
    mediaColumn,
    subheadline
  }

  return (
    <>
      {/* If an array of cards is recieved, maps the array, otherwise return a single card */}
      {items && items.length > 0 ? (
        items.map((item, index) => <CardMediaItem item={item} key={index} isLastChild={items.length - 1 === index} />)
      ) : (
        <Container className={'container container--narrow-xxl'} {...(zIndex === 0 && { style: { zIndex } })}>
          <CardMediaItem item={cardMediaItem} />
        </Container>
      )}
    </>
  )
}
export default CardMedia
