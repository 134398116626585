import { Page as DefaultPage } from '~modules'
import { components } from '../components'
import Image from 'next/image';

export default function Page({ page }) {

  if (!page) {
    return null;
  }
  return (<>
  <div className="bg-image-wrapper">
        <Image 
        src={page.bgImage}
        fill
        className="object-cover"
        />
      </div>
    <DefaultPage blok={page} components={components} />
    </>
  );
}

export async function getStaticProps({ params }) {
  const s3Response = await fetch('https://sphere-assets.msg.com/json/content.json')
  const { pages } = await s3Response.json()

  const currentPath = `/${params.slug.join('/')}`;
  const page = pages.find(page => page.path === currentPath) || {notfound: true};
  return {
    props: {
      page
    }
  };
}

export async function getStaticPaths() {
  const s3Response = await fetch('https://sphere-assets.msg.com/json/content.json')
  const { pages } = await s3Response.json()

  const paths = pages.map(page => {
    const slug = page.path.split('/').slice(1);
    return {params: {slug}};
  });
  return {paths, fallback: true};
}
