import React, { Suspense, useEffect } from 'react'
import { useRouter } from 'next/router'

interface BlokInterface {
  body: Array<object>
}

interface PageProps {
  blok: BlokInterface
  components: any
  constantsMap: object
}

const Page = ({ blok, components }: PageProps) => {
  const { content = [] } = blok
  const { asPath } = useRouter()

  const componentsList = content.map((block, index) => {
    const { componentName } = block
    const DynamicComponent = components[componentName]

    if (DynamicComponent) {
      return <DynamicComponent key={index} {...block} />
    }
  })

  return (
    <main>
      <Suspense fallback={<div>Loading...</div>}>{componentsList}</Suspense>
    </main>
  )
}

export default Page
