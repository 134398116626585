import React from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './Button.module.scss'
import { ConditionalWrapper } from '~elements'
import { ButtonProps } from '~types'

const Button = ({
  bgColor,
  disabled,
  isFullWidth,
  isTargetBlank,
  justify,
  linkId,
  id,
  linkModule,
  linkLocation,
  onClick,
  size = 'md',
  title,
  url,
  wantCfc = true
}: ButtonProps) => {
  const classNames = cx([
    styles['btn'],
    styles[`btn--${size}`],
    styles[`btn--justify-${justify}`],
    styles[`btn--${bgColor}`],
    styles[disabled || (!url && !onClick) ? `btn--is-disabled` : ''],
    styles[isFullWidth ? 'btn--is-full-width' : '']
  ])
  const { asPath, query } = useRouter()
  const requestCameFrom = query.camefrom
  const requestCameFromUrl = requestCameFrom && wantCfc ? `${url}?camefrom=${requestCameFrom}` : url


  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>, title: string, linkModule: string, linkLocation: string, linkId?: string) => {
    if (onClick) onClick(e)
    if (url) {
      if (isTargetBlank) {
        window.open(requestCameFromUrl, '_blank')
      } else {
        window.location.assign(requestCameFromUrl)
      }
    }
  }

  return (
    <ConditionalWrapper
      condition={size == 'lg'}
      wrapper={children => <div className={cx([styles['btn-lg-wrapper'], styles[`btn-lg-wrapper--justify-contents-${justify}`]])}>{children}</div>}
    >
      <button
        {...(id && { id })}
        className={classNames}
        disabled={disabled}
        onClick={() => {
          clickHandler(React.MouseEvent<HTMLButtonElement>, title, linkModule, linkLocation, linkId)
        }}
      >
        {title}
      </button>
    </ConditionalWrapper>
  )
}

Button.defaultProps = {
  bgColor: 'white',
  disabled: false,
  isFullWidth: false,
  isTargetBlank: false,
  justify: 'left',
  size: 'md'
}

export default Button
