import React from 'react'
import cx from 'classnames'
import styles from './Heading.module.scss'
import { HeadingProps } from '~types'
import { getFontSizeNumber } from 'utils'

const Heading = ({ children, fontSizeOverride, headingType, isEyebrow = false, isUppercase = false, justify = 'left' }: HeadingProps) => {
  const classNames = cx([
    styles[`heading`],
    styles[`h${headingType}`],
    styles[justify === 'center' ? `justify-${justify}` : ''],
    styles[isEyebrow ? 'eyebrow' : ''],
    styles[isUppercase ? 'to-uppercase' : ''],
    styles[fontSizeOverride ? `font-size-${getFontSizeNumber(fontSizeOverride)}` : '']
  ])
  const HeadingTag = `h${headingType}`

  return <HeadingTag className={classNames}>{children}</HeadingTag>
}

export default Heading
